const certifications = [
  {
    name: 'Google Data Analytics Certificate',
    date: 'July 2022',
  },
  {
    name: 'CySec Specialist (TÜV Rheinland) # 630/22 - Components',
    date: 'May 2022',
  },
];

export default certifications;